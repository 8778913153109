import { INTERACTIVE_CURSOR } from '../../../lib/constants';
import { DiscFace } from './disc-face';

export interface ITitleDiscChildren {
  shadow: Phaser.GameObjects.Arc;
  bottom: Phaser.GameObjects.Arc;
  top: Phaser.GameObjects.Arc;
  face: DiscFace;
}

export interface ITitleDiscChildrenProps {
  offset: number;
  shadowColor: number;
  bottomColor: number;
  topColor: number;
}

export interface ITitleDiscStateProps {
  hidden: boolean;
  clicked: boolean;
}

const defaultChildrenProps: ITitleDiscChildrenProps = {
  offset: 12,
  shadowColor: 0xBBBDBD,
  bottomColor: 0xD1969E,
  topColor: 0xF4A2AE,
};

export class TitleDisc extends Phaser.GameObjects.Container {
  name = 'titleDisc';

  children: ITitleDiscChildren;

  childrenProps: ITitleDiscChildrenProps;

  tweens: Phaser.Tweens.Tween[] = [];

  onClick: Function;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    size: number = 60,
    childrenProps: Partial<ITitleDiscChildrenProps> = {},
    onClick: Function = () => { },
  ) {
    super(scene, x, y);

    this.childrenProps = {
      ...defaultChildrenProps,
      ...childrenProps,
    };

    this.onClick = onClick;
    this.setInteractive({
      hitArea: new Phaser.Geom.Circle(0, 0, size),
      hitAreaCallback: Phaser.Geom.Circle.Contains,
      cursor: INTERACTIVE_CURSOR,
    });
    this.input!.cursor = INTERACTIVE_CURSOR;
    this.on('pointerup', () => { this.onClick(); });
    this.on('pointerover', () => { this.onHover(); });
    this.on('pointerout', () => { this.onOut(); });

    const { offset, shadowColor, bottomColor, topColor } = this.childrenProps;
    this.children = {
      shadow: new Phaser.GameObjects.Arc(scene, offset, 0, size, undefined, undefined, undefined, shadowColor),
      bottom: new Phaser.GameObjects.Arc(scene, 0, 0, size, undefined, undefined, undefined, bottomColor),
      top: new Phaser.GameObjects.Arc(scene, 0, -offset, size, undefined, undefined, undefined, topColor),
      face: new DiscFace(scene, 0, -offset),
    };
    const { shadow, bottom, top, face } = this.children;
    this.add([shadow, bottom, top, face]);
    this.reset();
    scene.add.existing(this);
  }

  reset() {
    this.setChildrenPosition(1);
  }

  onHover() {
    this.clearTweens();
    const t = this.scene.tweens.add({
      targets: [this],
      scale: 1.5,
      duration: 250,
      ease: 'Back',
    });
    this.tweens.push(
      t as Phaser.Tweens.Tween,
    );
  }

  onOut() {
    this.clearTweens();
    const t = this.scene.tweens.add({
      targets: [this],
      scale: 1,
      duration: 250,
      ease: 'Back',
    });
    this.tweens.push(
      t as Phaser.Tweens.Tween,
    );
  }

  clearTweens() {
    for (let i = 0; i < this.tweens.length; i++) {
      this.tweens[i].stop();
    }
    this.tweens = [];
  }

  setChildrenPosition(delta: number) {
    const d = delta < 0 ? 0 : delta;
    const position = d * this.childrenProps.offset;
    const { shadow, top, face } = this.children;
    shadow.x = position;
    top.y = -position;
    face.y = -position;
    return this;
  }
}
