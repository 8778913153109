import preload from './preload';
import create from './create';
import update from './update';

export default class GameScene extends Phaser.Scene {
  preload: (this: Phaser.Scene) => void;

  create: (this: Phaser.Scene) => void;

  update: (this: Phaser.Scene) => void;

  constructor(config: Phaser.Types.Scenes.SettingsConfig) {
    super(config);
    this.create = create;
    this.preload = preload;
    this.update = update;
  }
}
