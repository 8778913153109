import { IConnectedDotsMap } from '../phaser/classes/title-state/connected-dots';

export const TitleDotMap: IConnectedDotsMap = {
  dots: [
    // J
    { x: -395, y: -418 },
    { x: -326, y: -344 },
    { x: -316, y: -538 },
    // S
    { x: -276, y: -393 },
    { x: -194, y: -336 },
    { x: -236, y: -493 },
    { x: -174, y: -459 },
    // D
    { x: -131, y: -299 },
    { x: -119, y: -509 },
    { x: -31, y: -374 },
    // S
    { x: 71, y: -348 },
    { x: 142, y: -310 },
    { x: 106, y: -454 },
    { x: 157, y: -433 },
    // K
    { x: 210, y: -512 },
    { x: 212, y: -408 },
    { x: 223, y: -308 },
    { x: 261, y: -482 },
    { x: 274, y: -351 },
    // I
    { x: 337, y: -453 },
    { x: 320, y: -328 },
  ],
  connections: [
    // J
    { from: 0, to: 1 },
    { from: 1, to: 2 },
    // S
    { from: 3, to: 4 },
    { from: 4, to: 5 },
    { from: 5, to: 6 },
    // D
    { from: 7, to: 8 },
    { from: 8, to: 9 },
    { from: 9, to: 7 },
    // S
    { from: 10, to: 11 },
    { from: 11, to: 12 },
    { from: 12, to: 13 },
    // K
    { from: 14, to: 15 },
    { from: 15, to: 16 },
    { from: 15, to: 17 },
    { from: 15, to: 18 },
    // I
    { from: 19, to: 20 },
  ],
};
