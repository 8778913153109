import { DEBUG_UI_DEPTH, DEBUG_UI_RENDER } from '../../../lib/constants';
import { isDev } from '../../../lib/utils';

export class CameraFocus extends Phaser.GameObjects.Rectangle {
  name = 'cameraFocus';

  constructor(
    scene: Phaser.Scene,
    x: number = 0,
    y: number = 0,
  ) {
    const size = 16;
    super(scene, x, y, size, size, 0xFF0000);
    scene.add.existing(this);
    this.setDepth(DEBUG_UI_DEPTH);
    this.centerCamera(scene.cameras.main);
    this.setVisible(isDev ? DEBUG_UI_RENDER : false);
  }

  centerCamera(camera: Phaser.Cameras.Scene2D.Camera) {
    camera.startFollow(this);
  }
}
