import React from 'react';

const About: React.FC = () => (
  <>
    <p>
      <strong className="title">A Brief History</strong>
    </p>
    <p>
      I am an NYC-based developer with more than a decade of experience. I moved to New York in 2004 to complete my
      undergrad BFA in Jazz and Contemporary Music performance at The New School. For several years{' '}
      <strong>
        <a href="https://youtu.be/v1pbr9hapy0" rel="noopener noreferrer" target="_blank">
          I worked as a freelance musician (tenor sax)
        </a>{' '}
      </strong>
      both locally and overseas on tour.
    </p>
    <br />
    <p>
      In 2009 I went back to school to earn an MS in Digital Forensics & Cybersecurity from John Jay University.
      Learning how to{' '}
      <strong>
        <a href="https://youtu.be/Bmz67ErIRa4" rel="noopener noreferrer" target="_blank">
          hack the Gibson
        </a>{' '}
      </strong>
      re-ignited a childhood passion for programming. After graduation I worked briefly as a forensics lab technician
      and quickly realized that my heart was in coding. The rest is history!
    </p>
    <br />
    <p>
      <strong className="title">Fun Fact</strong>
    </p>
    <p>
      I shipped my first release in 1994. It was a birthday gift to my mother.{' '}
      <strong>
        <a href="https://youtu.be/OYECwCQpTDE" rel="noopener noreferrer" target="_blank">
          Super Mom
        </a>{' '}
      </strong>
      was a fighting simulator allowing her to viciously beat up either of her two children in a cage match. Initial
      feedback was &quot;I don&apos;t know how I feel about this...&quot; 11 year-old Jamaal considered it a huge
      success XD
    </p>
  </>
);

export default About;
