import React from 'react';

const Skills: React.FC = () => (
  <>
    <p>
      <strong className="title">Talents</strong>
    </p>
    <ul>
      <li>
        I have a strong sense of{' '}
        <strong>
          <a href="https://en.wikipedia.org/wiki/Game_feel" rel="noopener noreferrer" target="_blank">
            game feel
          </a>
        </strong>{' '}
        that I attempt to imbue into any project I work on, even if it isn&apos;t a game.
      </li>
      <li>
        If a key dependency is missing a feature or has a bug I am accustomed to modifying it. For example, this web app
        is using{' '}
        <strong>
          <a href="https://www.npmjs.com/package/jamaalsd-react-scripts" rel="noopener noreferrer" target="_blank">
            my custom version of react-scripts
          </a>
        </strong>{' '}
        to better support the React/Phaser integration.
      </li>
      <li>
        I have experience leading teams of developers. I make it a priority to support and mentor the junior members of
        the team.
      </li>
    </ul>
    <p>
      <strong className="title">Skillset</strong>
    </p>
    <p>
      <strong>Proficient</strong>
    </p>
    <ul>
      <li>
        <strong>
          <a href="https://vuejs.org/" rel="noopener noreferrer" target="_blank">
            Vue
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">
            React
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://phaser.io/" rel="noopener noreferrer" target="_blank">
            Phaser
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://www.typescriptlang.org/" rel="noopener noreferrer" target="_blank">
            TypeScript
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://en.wikipedia.org/wiki/JavaScript" rel="noopener noreferrer" target="_blank">
            JavaScript
          </a>
        </strong>{' '}
        (ES5 to ES2020)
      </li>
      <li>
        CSS & Related Variants (
        <strong>
          <a href="https://sass-lang.com/" rel="noopener noreferrer" target="_blank">
            SASS
          </a>
        </strong>
        /
        <strong>
          <a href="https://postcss.org/" rel="noopener noreferrer" target="_blank">
            PostCSS
          </a>
        </strong>
        )
      </li>
      <li>
        <strong>
          <a href="https://www.gatsbyjs.com/" rel="noopener noreferrer" target="_blank">
            Gatsby
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://angularjs.org/" rel="noopener noreferrer" target="_blank">
            AngularJS
          </a>
        </strong>
      </li>
    </ul>
    <p>
      <strong>Knowledgeable</strong>
    </p>
    <ul>
      <li>
        <strong>
          <a href="https://expressjs.com/" rel="noopener noreferrer" target="_blank">
            Express
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://www.socket.io/" rel="noopener noreferrer" target="_blank">
            Socket.IO
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://angular.io/" rel="noopener noreferrer" target="_blank">
            Angular
          </a>
        </strong>
      </li>
    </ul>
    <p>
      <strong>Some Experience</strong>
    </p>
    <ul>
      <li>
        <strong>
          <a href="https://unity.com/" rel="noopener noreferrer" target="_blank">
            Unity
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://pixijs.com/" rel="noopener noreferrer" target="_blank">
            PixiJS
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a
            href="https://en.wikipedia.org/wiki/C_Sharp_(programming_language)"
            rel="noopener noreferrer"
            target="_blank"
          >
            C#
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://www.python.org/" rel="noopener noreferrer" target="_blank">
            Python
          </a>
        </strong>
      </li>
    </ul>
  </>
);

export default Skills;
