export const react = {
  headers: {
    intro: 'Welcome to JSD.SKI',
    hello: 'Welcome to JSD.SKI',
    about: 'A Little About Myself',
    work: 'Work History & Project Vault',
    skills: 'Talents & Skillset',
  },
  loading: 'Loading',
};
