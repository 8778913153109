import { TFunction } from 'react-i18next';
import Cookies from 'universal-cookie';
import {
  COOKIE_CONFIG, COOKIE_NAMES,
  INTERACTIVE_CURSOR,
  PHASER_DOM_DEFAULT_TEXT_STYLE,
} from '../../../lib/constants';

export class SoundControl extends Phaser.GameObjects.DOMElement {
  camera: Phaser.Cameras.Scene2D.Camera;

  cookies: Cookies;

  muted: boolean = false;

  t: TFunction;

  constructor(scene: Phaser.Scene) {
    super(scene, 0, 0, 'button', {
      ...PHASER_DOM_DEFAULT_TEXT_STYLE,
      background: 'none',
      border: 'none',
      cursor: INTERACTIVE_CURSOR,
      padding: '0px',
    });
    scene.add.existing(this);
    this.camera = scene.cameras.main;
    this.setOrigin(0);
    this.t = scene.game.registry.get('t') as TFunction;
    this.cookies = new Cookies();
    const cookieValue = this.cookies.get('muted');
    if (cookieValue !== undefined) {
      // cookie is saved as a string
      this.setMute(cookieValue === 'true', false);
    } else {
      this.setMute(false, false);
    }
    this.setInteractive({
      hitArea: new Phaser.Geom.Rectangle(0, 0, this.width, this.height),
      hitAreaCallback: Phaser.Geom.Rectangle.Contains,
    });
    this.on('pointerup', () => { this.toogleMute(); });
    this.setVisible(false);
    this.disableInteractive();
    this.checkPosition();

    // run a cycle to give dom element time to position
    setTimeout(() => {
      this.setVisible(true);
    });
  }

  toogleMute() {
    this.setMute(!this.muted);
  }

  setMute(mute: boolean, setCookie: boolean = true) {
    if (setCookie) {
      const date = new Date();
      date.setDate(date.getDate() + COOKIE_CONFIG.LIFETIME);
      this.cookies.set(COOKIE_NAMES.MUTED, mute, { expires: date });
    }
    this.scene.sound.mute = mute;
    this.muted = mute;
    this.setText(this.t(mute ? 'soundOff' : 'soundOn'));
    this.resizeInput();
  }

  resizeInput() {
    // need to disable and delay re-enabling to prevent double touch
    this.disableInteractive();
    setTimeout(() => {
      this.input!.hitArea.setPosition(0, 0);
      this.input!.hitArea.setSize(this.width, this.height);
      this.setInteractive();
    }, 250);
  }

  checkPosition() {
    const { camera } = this;
    const desiredX = camera.worldView.left + 24;
    const desiredY = camera.worldView.bottom - this.height - 12;
    if (this.x !== desiredX || this.y !== desiredY) {
      this.setPosition(desiredX, desiredY);
    }
  }

  preUpdate() {
    this.checkPosition();
  }
}
