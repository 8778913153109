import React from 'react';
import PhaserGame from '../PhaserGame/PhaserGame';
import ProgressBar from '../ProgressBar/ProgressBar';
import TextOverlay from '../TextOverlay/TextOverlay';

const App: React.FC = () => {
  return (
    <>
      <PhaserGame />
      <TextOverlay />
      <ProgressBar />
    </>
  );
};

export default App;
