import React from 'react';

const Intro: React.FC = () => (
  <>
    <p>
      This website was built with{' '}
      <strong>
        <a href="https://reactjs.org" rel="noopener noreferrer" target="_blank">
          React
        </a>{' '}
        &{' '}
        <a href="https://phaser.io/" rel="noopener noreferrer" target="_blank">
          Phaser 3
        </a>
      </strong>
      . All art and sound were created by me unless credited below. Thank you for coming to my little corner of the
      internet!
    </p>
    <p>
      <i className="signature">Jamaal Sawyer-Dymski</i>
    </p>
    <p>
      I can be reached at{' '}
      <strong>
        <a href="mailto:jamaalsawyerd@gmail.com" rel="noopener noreferrer" target="_blank">
          jamaalsawyerd@gmail.com
        </a>
      </strong>
    </p>
    <br />
    <p>
      <strong className="title">Credits</strong>
    </p>
    <p>
      <strong>
        <a href="https://www.pixelsagas.com/" rel="noopener noreferrer" target="_blank">
          Pixel Saga
        </a>
      </strong>{' '}
      for the Hyperspace Font.
    </p>
    <p>
      <strong>
        <a href="https://harvettfox96.neocities.org/" rel="noopener noreferrer" target="_blank">
          Jayvee Enaguas
        </a>
      </strong>{' '}
      for the Pixel Operator Font.
    </p>
    <p>
      <strong>
        <a href="https://www.dafont.com/profile.php?user=205196" rel="noopener noreferrer" target="_blank">
          Omegaville
        </a>
      </strong>{' '}
      for the Cursive Font.
    </p>
    <p>
      <strong>
        <a href="http://trsp.net/" rel="noopener noreferrer" target="_blank">
          Steph Thirion
        </a>
      </strong>{' '}
      for making{' '}
      <strong>
        <a href="https://en.wikipedia.org/wiki/Eliss" rel="noopener noreferrer" target="_blank">Eliss Infinity</a>
      </strong>
      , the design inspiration for this site!
    </p>
  </>
);

export default Intro;
