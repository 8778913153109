import { convertRange } from '../../../lib/utils';
import { StateContainer } from './state-container';

export interface IState {
  id: string;
  state: StateContainer;
}

export class StateManager {
  scene: Phaser.Scene;

  activeState?: IState;

  states: IState[] = [];

  constructor(
    scene: Phaser.Scene,
    states: IState[] = [],
  ) {
    this.scene = scene;
    this.addStates(states);
  }

  addStates(states: IState[]) {
    this.states.push(...states);
  }

  setInitialActive(id: string, reset: boolean = true) {
    this.states.forEach((s) => {
      if (s.id === id) {
        this.activeState = s;
        s.state.setVisible(true);
        if (reset) {
          s.state.reset();
        }
      } else {
        s.state.setVisible(false);
      }
    });
  }

  async transitionTo(to: string): Promise<any> {
    return new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve) => {
        if (!this.activeState) {
          throw new Error('no active state has been set, did you call setInitialActive?');
        }
        const toStateElement = this.states.find((s) => s.id === to);
        if (!toStateElement) {
          throw new Error(`transitionTo: no state exists with key ${to}`);
        }

        const { main } = this.scene.cameras;
        const h = main.worldView.height;
        const fromState = this.activeState.state;
        const toState = toStateElement.state;
        fromState.setVisible(true);
        toState.setHold(true, false).setVisible(true).setY(h);
        await fromState.onExit();
        await toState.onEnter();
        const duration = h / 0.66;
        this.scene.tweens.addCounter({
          duration,
          from: 0,
          to: 1,
          ease: 'Power2',
          onUpdate: (t) => {
            const val = t.getValue();
            const fromY = convertRange(val, 0, 1, 0, -h);
            const toY = convertRange(val, 0, 1, h, 0);
            fromState.setY(fromY);
            toState.setY(toY);
          },
          onComplete: async () => {
            fromState.setVisible(false);
            this.activeState = toStateElement;
            await toState.onEnterComplete();
            resolve('transition complete');
          },
        });
      });
  }
}
