import { DEBUG_UI_DEPTH, DEBUG_UI_RENDER, RANGEFINDER_HEIGHT, RANGEFINDER_WIDTH } from '../../../lib/constants';
import { isDev } from '../../../lib/utils';
import { CameraFocus } from './camera-focus';

export class CameraRangeFinder extends Phaser.GameObjects.Rectangle {
  name = 'cameraRangeFinder';

  constructor(scene: Phaser.Scene, target: CameraFocus) {
    const strokeSize = 8;
    super(scene, target.x, target.y, RANGEFINDER_WIDTH - strokeSize, RANGEFINDER_HEIGHT - strokeSize);
    scene.add.existing(this);

    this.setStrokeStyle(strokeSize, 0xFF0000);
    this.setDepth(DEBUG_UI_DEPTH);
    this.setVisible(isDev ? DEBUG_UI_RENDER : false);
  }
}
