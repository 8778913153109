/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITextOverlayHeader {
  mKey: string;
  onClick: Function;
}

const TextOverlayHeader: React.FC<ITextOverlayHeader> = ({ mKey, onClick }) => {
  const { t } = useTranslation(['react']);
  return (
    <div className="header-container">
      <div className="header">
        <h1>{t(`headers.${mKey === 'hello' ? 'intro' : mKey}`)}</h1>
        <button type="button" onClick={(e: any) => onClick(e)}>
          X
        </button>
      </div>
      <hr />
    </div>
  );
};

export default TextOverlayHeader;
