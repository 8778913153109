import Phaser from 'phaser';
import GameScene from './scene/scene-class';

export const phaserGameConfig: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  title: 'JamaalSD Phaser Game',
  parent: 'phaser-game-container',
  pixelArt: false,
  scale: {
    mode: Phaser.Scale.RESIZE,
    width: '100%',
    height: '100%',
  },
  dom: {
    createContainer: true,
  },
  scene: GameScene,
  transparent: true,
  backgroundColor: 0xDFE1E0,
};
