import { TFunction } from 'react-i18next';
import {
  INTERACTIVE_CURSOR,
  PHASER_DOM_DEFAULT_TEXT_STYLE,
} from '../../../lib/constants';

const PDF_LINK = './assets/pdf/jamaalsd_resume.pdf';

export class ResumeButton extends Phaser.GameObjects.DOMElement {
  camera: Phaser.Cameras.Scene2D.Camera;

  constructor(scene: Phaser.Scene) {
    super(scene, 0, 0, 'button', {
      ...PHASER_DOM_DEFAULT_TEXT_STYLE,
      background: 'none',
      border: 'none',
      cursor: INTERACTIVE_CURSOR,
      padding: '0px',
    });
    scene.add.existing(this);
    this.camera = scene.cameras.main;
    this.setOrigin(1, 0);
    const t = scene.game.registry.get('t') as TFunction;
    this.setText(t('resume'));
    this.setInteractive({
      hitArea: new Phaser.Geom.Rectangle(0, 0, this.width, this.height),
      hitAreaCallback: Phaser.Geom.Rectangle.Contains,
    });
    this.on('pointerup', () => {
      window.open(PDF_LINK, '_blank');
      this.resizeInput();
    });
    this.setVisible(false);
    this.disableInteractive();
    this.checkPosition();
    this.resizeInput();

    // run a cycle to give dom element time to position
    setTimeout(() => {
      this.setVisible(true);
    });
  }

  resizeInput() {
    // need to disable and delay re-enabling to prevent double touch
    this.disableInteractive();
    setTimeout(() => {
      this.input!.hitArea.setPosition(-this.width, 0);
      this.input!.hitArea.setSize(this.width, this.height);
      this.setInteractive();
    }, 250);
  }

  checkPosition() {
    const { camera } = this;
    const desiredX = camera.worldView.right - 24;
    const desiredY = camera.worldView.bottom - this.height - 12;
    if (this.x !== desiredX || this.y !== desiredY) {
      this.setPosition(desiredX, desiredY);
    }
  }

  preUpdate() {
    this.checkPosition();
  }
}
