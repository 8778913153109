import { ANIM_DURATION } from '../../../components/TextOverlay/TextOverlay';
import { GridMapIndex } from '../../../lib/grid-maps';
import { devLog } from '../../../lib/utils';
import { CategorySelectButton } from '../button/category-select-button';
import { Grid, GridKeyColorMap } from './grid';
import { SquareButton } from '../button/square-button';

interface ICategoryControllerChildren {
  about: CategorySelectButton;
  hello: CategorySelectButton;
  skills: CategorySelectButton;
  work: CategorySelectButton;
  select: SquareButton;
}

export class CategoryController {
  scene: Phaser.Scene;

  currentCategory?: GridMapIndex;

  selectButton: SquareButton;

  children: ICategoryControllerChildren;

  grid: Grid;

  constructor(
    scene: Phaser.Scene,
    grid: Grid,
  ) {
    this.scene = scene;
    this.grid = grid;

    const selectButton = new SquareButton(scene, 0, 400, 300, undefined,
      () => {
        selectButton.click();
        scene.sound.get(`${this.getCurrentCategory()}Up`)!.play();
        PubSub.publish('showTextOverlay', {
          key: this.getCurrentCategory(),
          callback: () => {
            scene.sound.get(`${this.getCurrentCategory()}Down`)!.play();
            setTimeout(
              () => {
                selectButton.unclick(
                  undefined,
                  () => this.enableSelectButton(),
                );
              }, 200);
          },
          delay: (-1000 * ANIM_DURATION),
        });
        devLog(this.getCurrentCategory());
      });
    // sue me I want easy access
    this.selectButton = selectButton;
    this.selectButton.hide(0);

    const catBtnSize = 150;

    const helloButton = new CategorySelectButton(
      scene, 0 - ((catBtnSize) * 2.5), -300, catBtnSize, 'hello',
      () => this.selectCategory('hello'),
    );
    const aboutButton = new CategorySelectButton(
      scene, 0 - ((catBtnSize) * 0.85), -300, catBtnSize, 'about',
      () => this.selectCategory('about'),
    );
    const workButton = new CategorySelectButton(
      scene, 0 + ((catBtnSize) * 0.85), -300, catBtnSize, 'work',
      () => this.selectCategory('work'),
    );
    const skillsButton = new CategorySelectButton(scene, 0 + ((catBtnSize) * 2.5), -300, catBtnSize, 'skills',
      () => this.selectCategory('skills'),
    );

    this.children = {
      about: aboutButton,
      hello: helloButton,
      skills: skillsButton,
      work: workButton,
      select: selectButton,
    };
  }

  getCurrentCategory() {
    return this.currentCategory;
  }

  selectCategory(category: GridMapIndex) {
    this.scene.sound.get(`${category}Cat`)!.play();
    this.disableSelectButton();
    this.disableCategoryButtonsInteractive();
    const oldCategory = this.currentCategory;
    if (oldCategory) {
      const oldPressed = this.children[oldCategory];
      oldPressed.click(undefined, true, () => { });
      this.selectButton.hide(undefined, false);
    }
    const newPressed = this.children[category];
    newPressed.click(undefined, false, () => { },
      () => {
        this.enableCategoryButtonsInteractive();
      },
    );
    this.currentCategory = category;
    this.grid.tweenTilesWithKey(
      category,
      () => {
        this.setSelectButtonColors(category);
        this.selectButton.reveal(undefined, () => this.enableSelectButton());
        this.scene.sound.get(`${category}Sq`)!.play();
      },
    );
  }

  getChildren() {
    const { about, hello, skills, work, select } = this.children;
    return [
      { id: 'selectButton', go: select },
      { id: 'helloButton', go: hello },
      { id: 'aboutButton', go: about },
      { id: 'workButton', go: work },
      { id: 'skilsButton', go: skills },
    ];
  }

  disableCategoryButtonsInteractive() {
    const keys = Object.keys(this.children) as Array<keyof typeof this.children>;
    for (let i = 0; i < keys.length; i++) {
      const childKey = keys[i];
      if (childKey !== 'select') {
        const child = this.children[childKey] as CategorySelectButton;
        child.children.top.disableInteractive();
      }
    }
  }

  enableCategoryButtonsInteractive() {
    const keys = Object.keys(this.children) as Array<keyof typeof this.children>;
    for (let i = 0; i < keys.length; i++) {
      const childKey = keys[i];
      if (childKey !== 'select' && childKey !== this.currentCategory) {
        const child = this.children[childKey] as CategorySelectButton;
        child.children.top.setInteractive();
      }
    }
  }

  disableSelectButton() {
    this.selectButton.children.top.disableInteractive();
  }

  enableSelectButton() {
    this.selectButton.children.top.setInteractive();
  }

  setSelectButtonColors(category: GridMapIndex) {
    const colors = GridKeyColorMap[category];
    this.selectButton.setChildProps({
      bottomColor: colors.topColor,
      topColor: colors.bottomColor,
      coverColor: colors.coverColor,
      bottomClickedColor: colors.bottomColor,
    });
    const { cover, bottom } = this.selectButton.children;
    bottom.fillColor = this.selectButton.childrenProps.bottomColor;
    cover.fillColor = this.selectButton.childrenProps.coverColor;
  }
}
