import React, { ReactElement } from 'react';
import About from './Messages/About';
import Hello from './Messages/Hello';
import Skills from './Messages/Skills';
import Work from './Messages/Work';

interface ITextOverlayMessage {
  mKey: string;
}

function getTextOverlayMessage(mKey: string): ReactElement<any, any> | null {
  switch (mKey) {
    case 'hello':
    case 'intro':
      return <Hello />;
    case 'about':
      return <About />;
    case 'work':
      return <Work />;
    case 'skills':
      return <Skills />;
    default:
      return null;
  }
}

const TextOverlayMessage: React.FC<ITextOverlayMessage> = ({ mKey }) => {
  const content = getTextOverlayMessage(mKey);
  if (!content) return null;
  return <div className="message">{content}</div>;
};

export default TextOverlayMessage;
