import React, { useState, useEffect } from 'react';
import { isMobile } from 'mobile-device-detect';

interface IProgressDots {
  text?: string;
  startDelay?: number;
  dotInterval?: number;
}
let delayOnce = true;

const ProgressDots: React.FC<IProgressDots> = ({ text = '', startDelay = 4000, dotInterval = 250 }) => {
  const [dots, setDots] = useState<number>(-1);
  useEffect(() => {
    const interval = setTimeout(
      () => {
        const newDots = (dots + 1) % 4;
        setDots(newDots);
      },
      delayOnce ? startDelay : dotInterval,
    );
    delayOnce = false;
    return () => {
      clearTimeout(interval);
    };
  });
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return dots > -1 ? (
    <p className={`text progress ${isMobile ? 'mobile' : ''}`}>{`${text}${'.'.repeat(dots)}`}</p>
  ) : null;
};

export default ProgressDots;
