import { devLog, unlockAudioContext } from '../../lib/utils';

export default function preload(this: Phaser.Scene) {
  devLog('preload');
  this.sound.pauseOnBlur = false;
  this.cameras.main.setBackgroundColor(0xDFE1E0);
  this.load.on('progress', (value: number) => {
    PubSub.publish('loadingProgress', { value });
  });
  this.load.on('complete', () => {
    PubSub.publish('loadingComplete');
  });
  this.load.audio('part1', ['./assets/audio/part1.m4a']);
  this.load.audio('part2', ['./assets/audio/part2.m4a']);
  this.load.audio('part3', ['./assets/audio/part3.m4a']);
  this.load.audio('part4', ['./assets/audio/part4.m4a']);
  this.load.audio('part5', ['./assets/audio/part5.m4a']);
  this.load.audio('blink', ['./assets/audio/blink.m4a']);
  this.load.audio('shuffle', ['./assets/audio/shuffle.m4a']);
  this.load.audio('panelUp', ['./assets/audio/panel_up.m4a']);
  this.load.audio('panelDown', ['./assets/audio/panel_down.m4a']);
  this.load.audio('musicLoop', ['./assets/audio/music_loop.m4a']);
  this.load.audio('bigButtonPress', ['./assets/audio/big_button_press.m4a']);
  this.load.audio('helloCat', ['./assets/audio/hello_cat.m4a']);
  this.load.audio('aboutCat', ['./assets/audio/about_cat.m4a']);
  this.load.audio('workCat', ['./assets/audio/work_cat.m4a']);
  this.load.audio('skillsCat', ['./assets/audio/skills_cat.m4a']);
  this.load.audio('helloSq', ['./assets/audio/hello_sq.m4a']);
  this.load.audio('aboutSq', ['./assets/audio/about_sq.m4a']);
  this.load.audio('workSq', ['./assets/audio/work_sq.m4a']);
  this.load.audio('skillsSq', ['./assets/audio/skills_sq.m4a']);
  this.load.audio('helloUp', ['./assets/audio/hello_up.m4a']);
  this.load.audio('helloDown', ['./assets/audio/hello_down.m4a']);
  this.load.audio('aboutUp', ['./assets/audio/about_up.m4a']);
  this.load.audio('aboutDown', ['./assets/audio/about_down.m4a']);
  this.load.audio('workUp', ['./assets/audio/work_up.m4a']);
  this.load.audio('workDown', ['./assets/audio/work_down.m4a']);
  this.load.audio('skillsUp', ['./assets/audio/skills_up.m4a']);
  this.load.audio('skillsDown', ['./assets/audio/skills_down.m4a']);
  unlockAudioContext();
}
