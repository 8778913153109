import { isMobile } from 'mobile-device-detect';
import { RANGEFINDER_HEIGHT, RANGEFINDER_WIDTH } from './constants';

export const isDev = process.env.NODE_ENV === 'development';

export function setCameraZoom(
  c: Phaser.Cameras.Scene2D.Camera,
  instant: boolean = false,
) {
  const widthZoom = Phaser.Math.Clamp(c.width / RANGEFINDER_WIDTH, 0.25, 1);
  const heightZoom = Phaser.Math.Clamp(c.height / RANGEFINDER_HEIGHT, 0.25, 1);
  const desiredZoom = widthZoom > heightZoom ? heightZoom : widthZoom;

  if (c.zoom.toFixed(3) !== desiredZoom.toFixed(3)) {
    c.setZoom(
      Phaser.Math.Linear(
        c.zoom,
        desiredZoom,
        instant ? 1 : 0.5,
      ),
    );
  }
}

export function convertRange(
  oVal: number,
  oMin: number,
  oMax: number,
  nMin: number,
  nMax: number,
) {
  const oRange = (oMax - oMin);
  const nRange = (nMax - nMin);
  return (((oVal - oMin) * nRange) / oRange) + nMin;
}

export function devLog(...data: any[]) {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.log(...data);
  }
}

export function devLogWarn(...data: any[]) {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.warn(...data);
  }
}

export function devLogErr(...data: any[]) {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.error(...data);
  }
}
export function unlockAudioContext() {
  const unlockHandler = () => {
    const audio: HTMLElement | null = document.getElementById('audioUnlock');
    if (audio instanceof HTMLAudioElement) {
      audio.play();
    }
    window.removeEventListener('touchstart', unlockHandler);
    window.removeEventListener('touchend', unlockHandler);
    window.removeEventListener('click', unlockHandler);
    window.removeEventListener('keydown', unlockHandler);
  };
  window.addEventListener('touchstart', unlockHandler, false);
  window.addEventListener('touchend', unlockHandler, false);
  window.addEventListener('click', unlockHandler, false);
  window.addEventListener('keydown', unlockHandler, false);
}

export function stopEventPropagation(e: any) {
  if (isMobile) {
    e.stopPropagation();
  } else {
    e.stopPropagation();
  }
}

export function removeWidows(id: string, tags: string[]) {
  const idEl = document.getElementById(id);
  if (!idEl) return;
  for (let i = 0; i < tags.length; i += 1) {
    const tag = tags[i];
    const elems = idEl.getElementsByTagName(tag);
    for (let j = 0; j < elems.length; j += 1) {
      const el = elems[j].innerHTML.split(' ');
      if (el.length > 2) {
        el[el.length - 2] += `&nbsp;${el[el.length - 1]}`;
        el.pop();
      }
      elems[j].innerHTML = el.join(' ');
    }
  }
}

export function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function makeSubArrays(arr: any[], min: number = 1, max: number = 1) {
  const returnArr = [];
  while (arr.length) {
    const between = Phaser.Math.Between(min, max);
    const size = between > arr.length ? arr.length : between;
    returnArr.push(arr.splice(0, size));
  }
  return returnArr;
}
