import { TFunction } from 'react-i18next';
import {
  PHASER_DOM_DEFAULT_TEXT_STYLE,
} from '../../../lib/constants';

export class MainInstructions extends Phaser.GameObjects.DOMElement {
  constructor(scene: Phaser.Scene) {
    super(scene, 0, -675, 'button', {
      ...PHASER_DOM_DEFAULT_TEXT_STYLE,
      fontSize: '72px',
      background: 'none',
      border: 'none',
      padding: '0px',
      width: '1024px',
    });
    scene.add.existing(this);
    const t = scene.game.registry.get('t') as TFunction;
    this.setHTML(t('instructions'));
    this.setOrigin(0.5, 0);
  }
}
