/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

const Work: React.FC = () => (
  <>
    <p>
      <strong className="title">Recent Work History</strong>
    </p>
    <p>
      This list reflects the most recent positions I&apos;ve held.{' '}
      <strong>
        <a href="./assets/pdf/jamaalsd_resume.pdf" rel="noopener noreferrer" target="_blank">
          A complete listing can be found in my resume.
        </a>
      </strong>
    </p>
    <br />
    <p>
      <strong>The Game Agency/ELB Learning [2015-2018, 2019-current]</strong>
    </p>
    <p>
      <i>Lead Developer</i>
    </p>
    <p>
      Lead development for{' '}
      <strong>
        <a href="https://thegameagency.com/" rel="noopener noreferrer" target="_blank">
          The Games Agency
        </a>
      </strong>
      , a division of{' '}
      <strong>
        <a href="https://www.elblearning.com/" rel="noopener noreferrer" target="_blank">
          ELB Learning.
        </a>
      </strong>{' '}
      TGA provides game-based solutions to complement training initiatives and improve learning
      outcomes.{' '}
      <strong>
        <a href="https://thegameagency.com/case-studies-category/" rel="noopener noreferrer" target="_blank">
          Winner of 10+ awards since 2015 from Demo Fest, International Serious Play, and Training Industry.
        </a>
      </strong>
    </p>
    <br />
    <p>
      <strong>Voyager [2018-2019]</strong>
    </p>
    <p>
      <i>Growth Engineer</i>
    </p>
    <p>
      Developed and maintained code for the marketing initiatives of{' '}
      <strong>
        <a href="https://investvoyager.com" rel="noopener noreferrer" target="_blank">
          Voyager
        </a>
      </strong>
      , a mobile cryptocurrency app. Built the marketing{' '}
      <strong>
        <a href="https://investvoyager.com" rel="noopener noreferrer" target="_blank">
          website
        </a>{' '}
      </strong>{' '}
      and{' '}
      <strong>
        <a href="https://investvoyager.com/blog" rel="noopener noreferrer" target="_blank">
          blog
        </a>
      </strong>
      , created a bespoke referral rewards program, and integrated user app activity into marketing workflows.
    </p>
    <br />
    <p>
      <strong className="title">Project Vault</strong>
    </p>
    <p className="warning">
      <br />
      Do not attempt to play these projects on a mobile device!
    </p>
    <br />
    <p>
      <strong>Wounded [2020]</strong>
    </p>
    <p>
      Local multiplayer game made for{' '}
      <strong>
        <a href="https://globalgamejam.org/" rel="noopener noreferrer" target="_blank">
          Global Game Jam 2020
        </a>
      </strong>
      . The theme was &quot;repair&quot;, so we built a game with two doctors competitively fighting to heal each other
      to full health! Built with{' '}
      <strong>
        <a href="https://phaser.io/" rel="noopener noreferrer" target="_blank">
          Phaser 3
        </a>
      </strong>{' '}
      and{' '}
      <strong>
        <a href="https://ftbstudio.net/wounded" rel="noopener noreferrer" target="_blank">
          online and available to play.
        </a>
      </strong>{' '}
      We were a big hit with the 10 year olds in attendance XD
    </p>
    <br />
    <p>
      <strong>Super JanKenPow [2015]</strong>
    </p>
    <p>
      Multiplayer fighting game for iOS based on Rock, Paper, Scissors. Built in JavaScript with the{' '}
      <strong>
        <a href="https://impactjs.com/" rel="noopener noreferrer" target="_blank">
          ImpactJS library
        </a>
      </strong>{' '}
      and ported to mobile with{' '}
      <strong>
        <a href="https://cordova.apache.org/" rel="noopener noreferrer" target="_blank">
          Apache Cordova
        </a>
      </strong>
      . Sadly, the app is no longer available on the App Store. However,{' '}
      <strong>
        <a href="https://ftbstudio.net/sjkp" rel="noopener noreferrer" target="_blank">
          a single-player dev build is uploaded and playable.
        </a>
      </strong>
    </p>
    <br />
    <p>
      <strong>Slerp [2014]</strong>
    </p>
    <p>
      2D action puzzle game built with the{' '}
      <strong>
        <a href="https://unity.com/" rel="noopener noreferrer" target="_blank">
          Unity Engine
        </a>
      </strong>{' '}
      and released on iOS in 2014. I was trying to create a mobile game that felt like{' '}
      <strong>
        <a href="https://en.wikipedia.org/wiki/Zoop" rel="noopener noreferrer" target="_blank">
          Zoop
        </a>
      </strong>
      . The app is no longer available on the App Store.{' '}
      <strong>
        <a href="https://ftbstudio.net/slerp" rel="noopener noreferrer" target="_blank">
          A web build is playable online.
        </a>
      </strong>
    </p>
  </>
);

export default Work;
