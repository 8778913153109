/* eslint-disable @typescript-eslint/no-unused-vars */
import { StateContainer } from '../../../classes/state/state-container';
import { CameraFocus } from '../../../classes/camera/camera-focus';
import { SquareButton } from '../../../classes/button/square-button';
import { MainInstructions } from '../../../classes/main-state/main-instruction';

export class MainStateContainer extends StateContainer {
  name = 'mainStateContainer';

  tweens: Phaser.Tweens.Tween[] = [];

  constructor(
    scene: Phaser.Scene,
    x: number = 0,
    y: number = 0,
    children: any[] = [],
    target: CameraFocus,
    holdX: boolean = true,
    holdY: boolean = false,
  ) {
    super(scene, x, y, children, target, holdX, holdY);
  }

  reset() {
     // get things in position
     const selectButton: SquareButton = this.getChildById('selectButton');
     const instructions: MainInstructions = this.getChildById('instructions');
     instructions.setAlpha(0).setVisible(false);
  }

  async onEnter() {
    // get things in position
    this.scene.sound.pauseOnBlur = true;
    const instructions: MainInstructions = this.getChildById('instructions');
    instructions.setVisible(false);
 }
}
