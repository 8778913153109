/* eslint-disable @typescript-eslint/no-use-before-define */
import { convertRange } from '../../../../lib/utils';
import { ConnectedDots } from '../../../classes/title-state/connected-dots';
import { CrossButton } from '../../../classes/button/cross-button';
import { SquareButton } from '../../../classes/button/square-button';
import { TitleDisc } from '../../../classes/title-state/title-disc';
import { TitleStateContainer } from './title-state';

export async function titleIntroAnimation(sc: TitleStateContainer): Promise<any> {
  return new Promise((resolve) => {
    const { tweens, sound } = sc.scene;
    // get things in position
    const nextButton: SquareButton = sc.getChildById('nextButton');
    const crossButton: CrossButton = sc.getChildById('crossButton');
    const dots: ConnectedDots = sc.getChildById('dots');
    const titleDisc: TitleDisc = sc.getChildById('titleDisc');
    const clickText: Phaser.GameObjects.Text = sc.getChildById('clickText');

    // persistent vars needed for tweens
    const chosenDotIndex = 9;
    const gotoY = -500;
    const camera = sc.scene.cameras.main;
    const currentCamBack = Phaser.Display.Color.ValueToColor(0xDFE1E0);
    const flashCamBack = Phaser.Display.Color.ValueToColor(0xFFFDFF);
    const shadowOffset = 10;
    let triggerP2 = false;
    let triggerDecompress = false;
    let discPastThresh = false;
    let triggerVal = 0;
    let threshY = 0;
    let threshVal = 0;

    const dotsRise = tweens.addCounter({
      duration: 1000,
      from: 0,
      to: 1,
      paused: true,
      ease: 'Back',
      onStart: () => {
        sound.get('part1')!.play();
      },
      onUpdate: (t) => {
        const val = t.getValue();
        const textAlpha = Phaser.Math.Clamp(convertRange(val, 0, 1, 1, 0), 0, 1);
        clickText.setAlpha(textAlpha);
        const dotsPosY = convertRange(val, 0, 1, nextButton.y, gotoY);

        dots.compress(0, dotsPosY, [chosenDotIndex]);
        if (t.progress > 0.925 && !triggerP2) {
          triggerP2 = true;
          faceRiseAndDotsDecompress.play();
          cameraFlashUp.play();
        }
      },
      onComplete: () => {
        clickText.setVisible(false);
        triggerDecompress = true;
        sound.get('part2')!.play();
      },
    });

    const faceRiseAndDotsDecompress = tweens.addCounter({
      duration: 1000,
      from: 0,
      to: 1,
      paused: true,
      ease: 'Back.easeOut',
      easeParams: [1.2],
      onUpdate: (t) => {
        const val = t.getValue();
        const discY = convertRange(val, 0, 1, nextButton.y, gotoY);
        const chosenDot = dots.getDot(chosenDotIndex);
        const origPos = chosenDot.getData('origPos');
        if (triggerDecompress) {
          if (!triggerVal) {
            triggerVal = val;
          }
          dots.decompress(convertRange(val, triggerVal, 1, 0, 1), [chosenDotIndex]);
        }
        if (!discPastThresh) {
          chosenDot.setY(discY);
          discPastThresh = discY < origPos.y;
          threshY = discY;
          threshVal = val;
        } else {
          const dotPos = {
            x: convertRange(val, threshVal, 1, 0, origPos.x),
            y: convertRange(val, threshVal, 1, threshY, origPos.y),
          };
          chosenDot.setPosition(dotPos.x, dotPos.y);
        }
        titleDisc.y = discY;
        dots.setShadowOffset(0);
      },
      onComplete: () => {
        setOffset.play();
      },
    });

    const cameraFlashUp = tweens.addCounter({
      duration: 400,
      from: 0,
      to: 1,
      paused: true,
      onUpdate: (t) => {
        const val = t.getValue();
        const clampedVal = Phaser.Math.Clamp(val, 0, 1);
        const color = Phaser.Display.Color.Interpolate.ColorWithColor(currentCamBack, flashCamBack, 1, clampedVal);
        camera.setBackgroundColor(parseInt(Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a).replace(/^#/, ''), 16));
      },
      onComplete: () => {
        cameraFlashDown.play();
      },
    });

    const cameraFlashDown = tweens.addCounter({
      duration: 1000,
      from: 0,
      to: 1,
      paused: true,
      onUpdate: (t) => {
        const val = t.getValue();
        const clampedVal = Phaser.Math.Clamp(val, 0, 1);
        const color = Phaser.Display.Color.Interpolate.ColorWithColor(flashCamBack, currentCamBack, 1, clampedVal);
        camera.setBackgroundColor(parseInt(Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a).replace(/^#/, ''), 16));
      },
    });
    const setOffset = sc.scene.tweens.addCounter({
      delay: 200,
      duration: 500,
      from: 0,
      to: 1,
      ease: 'Back',
      paused: true,
      onStart: () => {
        sound.get('part3')!.play();
      },
      onUpdate: (t) => {
        const val = t.getValue();
        const so = convertRange(val, 0, 1, 0, shadowOffset);
        dots.setShadowOffset(so);
        // need to make it look like the dots are rising
        for (let i = 0; i < dots.dots.length; i++) {
          const d = dots.dots[i];
          const op = d.getData('origPos');
          d.setPosition(op.x, op.y - so);
        }
        titleDisc.setChildrenPosition(val);
      },
      completeDelay: 1000,
      onComplete: () => {
        titleDisc.children.face.setBlink(true, Phaser.Math.Between(1000, 2000));
        nextButton.setVisible(true).hide(undefined, true);
        sound.get('part4')!.play();
        scaleInCross.play();
      },
    });

    const scaleInCross = tweens.addCounter({
      delay: 250,
      duration: 125,
      from: 0,
      to: 1,
      paused: true,
      onStart: () => {
        sound.get('part5')!.play();
        crossButton.setVisible(true).setScale(0);
      },
      onUpdate: (t) => {
        const val = t.getValue();
        crossButton.setScale(val);
      },
      onComplete: () => {
        crossButton.setInteractive();
        nextButton.children.top.setInteractive();
        resolve('init done');
      },
    });

    sc.tweens.push(dotsRise);
    sc.tweens.push(faceRiseAndDotsDecompress);
    sc.tweens.push(cameraFlashUp);
    sc.tweens.push(cameraFlashDown);
    sc.tweens.push(setOffset);
    sc.tweens.push(scaleInCross);
    dotsRise.play();
  });
}
