import { isMobile } from 'mobile-device-detect';

export const DEBUG_UI_DEPTH = 999;
export const DEBUG_UI_RENDER = false;
export const RANGEFINDER_WIDTH = isMobile ? 1024 : 1400;
export const RANGEFINDER_HEIGHT = 1400;
export const INTERACTIVE_CURSOR = 'pointer';

export const DEFAULT_AUDIO_LEVEL = 0.5;

export const PHASER_DOM_DEFAULT_TEXT_STYLE = {
  textAlign: 'center',
  fontFamily: 'Hyperspace',
  fontSize: '90px',
  color: 'black',
  cursor: 'default',
};

export const COOKIE_NAMES = {
  MUTED: 'muted',
};

export const COOKIE_CONFIG = {
  LIFETIME: 30, // Lifetime of cookies in days
};
