import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { removeWidows, stopEventPropagation } from '../../lib/utils';
import TextOverlayHeader from './TextOverlayHeader';
import TextOverlayMessage from './TextOverlayMessage';
import './TextOverlay.scss';

export const ANIM_DURATION = 0.33;

const DEBUG_SHOW = false;
const DEBUG_MESSAGE_KEY: string = 'work';

let onExit = () => {};
let onExitDelay = 0;

const TextOverlay: React.FC = () => {
  const [render, setRender] = useState(false);
  const [messageKey, setMessageKey] = useState<string>('');
  const [animating, setAnimating] = useState(false);
  const shouldRender = DEBUG_SHOW || (render && messageKey);
  useEffect(() => {
    if (shouldRender) {
      removeWidows('to', ['h1', 'p', 'ul']);
    }
  }, [shouldRender]);

  useEffect(() => {
    PubSub.subscribe('showTextOverlay', subscriber);
  }, []);

  function subscriber(msg: string, data: any) {
    const { key, callback, delay } = data;
    setRender(true);
    setAnimating(true);
    setMessageKey(key);
    onExit = callback || (() => {});
    onExitDelay = delay !== undefined ? delay : 0;
  }

  const closeOverlay = useCallback(
    (e: any) => {
      stopEventPropagation(e);
      if (animating) return;
      setAnimating(true);
      const elementToScroll = document.querySelector('.to .outer');
      elementToScroll?.scrollTo(0, 0);
      setRender(false);
      const delay = ANIM_DURATION * 1000 + onExitDelay;
      setTimeout(
        () => {
          onExit();
        },
        delay < 0 ? 0 : delay,
      );
    },
    [animating],
  );

  const textMessageKey: string = DEBUG_SHOW ? DEBUG_MESSAGE_KEY : messageKey;

  return (
    <AnimatePresence>
      {shouldRender && (
        <div className={`to ${textMessageKey}`} id="to">
          <motion.div
            key="toScrim"
            className="scrim"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.33 }}
            exit={{ opacity: 0 }}
            transition={{ duration: ANIM_DURATION }}
          />
          <motion.div
            className="outer"
            onMouseUp={closeOverlay}
            style={{
              overflow: animating ? 'hidden' : 'auto',
            }}
            exit={{ overflow: 'hidden' }}
          >
            <motion.div
              key="toContent"
              className="content"
              onTouchStart={stopEventPropagation}
              onMouseUp={stopEventPropagation}
              transition={{ duration: ANIM_DURATION }}
              onAnimationComplete={() => {
                setAnimating(false);
              }}
              initial={{ y: '110%' }}
              animate={{ y: '0%' }}
              exit={{ y: '110%' }}
            >
              <TextOverlayHeader mKey={textMessageKey} onClick={closeOverlay} />
              <TextOverlayMessage mKey={textMessageKey} />
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default TextOverlay;
