/* eslint-disable jsx-a11y/media-has-caption */
import Phaser from 'phaser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { phaserGameConfig } from '../../phaser/game-config';

const PhaserGame: React.FC = () => {
  const [game, setGame] = useState<Phaser.Game | undefined>(undefined);
  const { t } = useTranslation(['phaser']);
  useEffect(() => {
    if (game) {
      return;
    }
    const phaserGame = new Phaser.Game({
      ...phaserGameConfig,
    });
    phaserGame.registry.set('t', t);
    setGame(phaserGame);
  }, [setGame, game, t]);
  return (
    <>
      <audio id="audioUnlock">
        <source src="./assets/audio/unlock.mp3" type="audio/mpeg" />
      </audio>
      <div id="phaser-game-container" />
    </>
  );
};

export default PhaserGame;
