/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridMap, GridMapIndex } from '../../../lib/grid-maps';
import { makeSubArrays } from '../../../lib/utils';
import { GridTile } from './grid-tile';

export class Grid {
  tiles: GridTile[] = [];

  tweens: Phaser.Tweens.Tween[] = [];

  timers: Phaser.Time.TimerEvent[] = [];

  scene: Phaser.Scene;

  constructor(
    scene: Phaser.Scene,
    startX: number,
    startY: number,
    cols: number,
    rows: number,
    size: number = 20,
  ) {
    this.scene = scene;
    for (let i = 0; i < cols; i += 1) {
      for (let j = 0; j < rows; j += 1) {
        const tile = new GridTile(
          scene,
          startX + i * size,
          startY + j * size,
          size,
          this.tiles.length,
          { offset: size },
        );
        this.tiles.push(tile);
      }
    }
  }

  getTiles() {
    return [...this.tiles];
  }

  setTilesWithVal(val: number) {
    for (let i = 0; i < this.tiles.length; i++) {
      this.tiles[i].setChildrenPosition(val);
    }
  }

  setTilesWithKey(key: GridMapIndex) {
    const tileMap = GridMap[key];
    for (let i = 0; i < this.tiles.length; i++) {
      if (tileMap[i]) {
        this.setTileColors(this.tiles[i], key);
      }
      this.tiles[i].setChildrenPosition(tileMap[i] ? 1 : 0);
    }
  }

  tweenTilesWithKey(key: GridMapIndex, onComplete: Function = () => { }) {
    this.clearTweensAndTimers();
    const tileMap = GridMap[key];
    const shouldGoUp = this.getTiles().filter((t) => tileMap[t.index]);
    const shouldGoDown = this.getTiles().filter(
      (t1) => t1.currentDelta && shouldGoUp.findIndex((t2) => t1.index === t2.index) === -1,
    );

    const tilesArray = makeSubArrays(
      Phaser.Utils.Array.Shuffle([
        ...shouldGoDown,
        ...shouldGoUp,
      ]), 15, 20,
    );

    let delay = 0;
    let completeTime = 0;

    for (let i = 0; i < tilesArray.length; i++) {
      const tiles = tilesArray[i];
      for (let j = 0; j < tiles.length; j++) {
        const tile = tiles[j];
        if (tile.currentDelta) {
          const onlyDown = shouldGoDown.findIndex((t) => t.index === tile.index) !== -1;
          this.tweenTileDown(
            tile, delay, onlyDown ? undefined : () => this.tweenTileUp(tile, 0, key),
          );
          const time = ANIM_DURATION * (onlyDown ? 1 : 2) + delay;
          if (time > completeTime) {
            completeTime = time;
          }
        } else {
          this.tweenTileUp(tile, delay, key);
          const time = ANIM_DURATION + delay;
          if (time > completeTime) {
            completeTime = time;
          }
        }
      }
      delay += ANIM_DELAY_BY;
    }
    this.timers.push(
      this.scene.time.addEvent({
        startAt: ANIM_DURATION,
        delay: ANIM_DURATION, // ms
        callback: () => {
          this.scene.sound.play('shuffle', { volume: 0.3 });
        },
        repeat: (completeTime + 1) / ANIM_DURATION,
      }),
    );
    this.timers.push(
      this.scene.time.addEvent({
        delay: completeTime + ANIM_DURATION,
        callback: () => onComplete(),
      }),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  setTileColors(tile: GridTile, key: GridMapIndex) {
    const colors = GridKeyColorMap[key];
    tile.setChildProps({
      ...colors,
    });
    // eslint-disable-next-line no-param-reassign
    tile.children.bottom.fillColor = colors.bottomColor;
  }

  tweenTileUp(tile: GridTile, delay: number = 0, key: GridMapIndex) {
    const { tweens } = this.scene;
    this.setTileColors(tile, key);
    this.tweens.push(
      tweens.addCounter({
        from: 0,
        to: 1,
        duration: ANIM_DURATION,
        delay,
        ease: ANIM_EASE,
        onUpdate: (t) => {
          tile.setChildrenPosition(t.getValue());
        },
      }),
    );
  }

  tweenTileDown(tile: GridTile, delay: number = 0, onComplete: Function = () => { }) {
    const { tweens } = this.scene;
    this.tweens.push(
      tweens.addCounter({
        from: tile.currentDelta,
        to: 0,
        duration: ANIM_DURATION,
        delay,
        ease: ANIM_EASE,
        onUpdate: (t) => {
          tile.setChildrenPosition(t.getValue());
        },
        onComplete: () => { onComplete(); },
      }),
    );
  }

  clearTweensAndTimers() {
    for (let i = 0; i < this.tweens.length; i++) {
      this.tweens[i].stop();
    }
    this.tweens = [];
    for (let i = 0; i < this.timers.length; i++) {
      this.timers[i].remove();
    }
    this.timers = [];
  }
}

const ANIM_DURATION = 100;
const ANIM_DELAY_BY = 100;
const ANIM_EASE = 'Linear';

export const GridKeyColorMap = {
  about: {
    topColor: 0xD90368,
    bottomColor: 0x790239,
    coverColor: 0x3C011C,
  },
  work: {
    topColor: 0x04A777,
    bottomColor: 0x025038,
    coverColor: 0x01281B,
  },
  select: {
    topColor: 0x909090,
    bottomColor: 0x505050,
    coverColor: 0x000000,
  },
  skills: {
    topColor: 0x53B3CB,
    bottomColor: 0x287A8D,
    coverColor: 0x143C46,
  },
  hello: {
    topColor: 0xf9d347,
    bottomColor: 0xd0a507,
    coverColor: 0x947504,
  },
};
