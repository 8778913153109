import { isMobile } from 'mobile-device-detect';
import { INTERACTIVE_CURSOR } from '../../../lib/constants';

export interface ICrossButtonChildren {
  line1: Phaser.GameObjects.Line;
  line2: Phaser.GameObjects.Line;
}

export interface ICrossButtonChildrenProps {
  size: number;
  width: number;
  color: number;
}

export interface ICrossButtonStateProps {
  hidden: boolean;
  clicked: boolean;
}

const defaultChildrenProps: ICrossButtonChildrenProps = {
  size: 100,
  width: 3,
  color: 0x000000,
};

export class CrossButton extends Phaser.GameObjects.Container {
  name = 'crossButton';

  children: ICrossButtonChildren;

  childrenProps: ICrossButtonChildrenProps;

  buttonState: ICrossButtonStateProps;

  onClick: Function;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    childrenProps: Partial<ICrossButtonChildrenProps> = {},
    onClick: Function = () => { },
  ) {
    super(scene, x, y);

    this.childrenProps = {
      ...defaultChildrenProps,
      ...childrenProps,
    };

    this.buttonState = {
      hidden: false,
      clicked: false,
    };

    this.onClick = onClick;

    const { size, color, width } = this.childrenProps;
    this.children = {
      line1: new Phaser.GameObjects.Line(scene, 0, 0, -size / 2, 0, size / 2, 0, color),
      line2: new Phaser.GameObjects.Line(scene, 0, 0, 0, -size / 2, 0, size / 2, color),
    };
    const { line1, line2 } = this.children;
    line1.setOrigin(0).setLineWidth(width);
    line2.setOrigin(0).setLineWidth(width);
    this.add([line1, line2]);
    const haSize = isMobile ? size * 1.33 : size;
    this.setInteractive({
      hitArea: new Phaser.Geom.Rectangle(-haSize / 2, -haSize / 2, haSize, haSize),
      hitAreaCallback: Phaser.Geom.Rectangle.Contains,
      cursor: INTERACTIVE_CURSOR,
    });
    this.input!.cursor = INTERACTIVE_CURSOR;
    this.on('pointerup', () => { this.onClick(); });
    scene.add.existing(this);
  }

  hide(duration = 125) {
    this.scene.tweens.addCounter({
      duration,
      from: 1,
      to: 0,
      onUpdate: (t) => {
        this.setScale(t.getValue());
      },
      onComplete: () => {
        this.disableInteractive();
      },
    });
  }

  show(duration = 125) {
    this.scene.tweens.addCounter({
      duration,
      from: 0,
      to: 1,
      onUpdate: (t) => {
        this.setScale(t.getValue());
      },
      onComplete: () => {
        this.setInteractive();
      },
    });
  }
}
