import React, { useState, useEffect } from 'react';
import './ProgressBar.scss';
import { useTranslation } from 'react-i18next';
import ProgressDots from './ProgressDots';
import ProgressPercent from './ProgressPercent';

const ProgressBar: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation(['react']);

  useEffect(() => {
    PubSub.subscribe('loadingComplete', () => {
      setLoading(false);
    });
  }, []);
  return loading ? (
    <>
      <ProgressDots text={t('loading')} />
      <ProgressPercent />
    </>
  ) : null;
};

export default ProgressBar;
