import { convertRange, devLog } from '../../../lib/utils';

export const DEBUG_DRAG_DOTS = false;

interface IShadowPosition {
  x: number;
  y: number;
}

const DOT_SIZE = 12;
const DOT_COLOR = 0x000000;
const DOT_SHADOW_COLOR = 0xBBBDBD;
export const DOT_SHADOW_OFFSET = 10;

class Dot extends Phaser.GameObjects.Arc {
  name = 'dot';

  shadow: Dot | undefined;

  shadowOffset: IShadowPosition = { x: 0, y: 0 };

  shadowFollow: boolean = true;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    index?: number,
    isShadow: boolean = false,
  ) {
    super(scene, x, y, DOT_SIZE, undefined, undefined, undefined, !isShadow ? DOT_COLOR : DOT_SHADOW_COLOR);
    this.setData('origPos', { x, y });
    this.setData('compressedPos', { x, y });
    // if no index assumming it is a shadow
    if (!isShadow) {
      this.name = 'dot';
      this.setData('dotIndex', index);

      this.shadow = new Dot(scene, x + this.shadowOffset.x, y + this.shadowOffset.y, undefined, true);
      if (DEBUG_DRAG_DOTS) {
        this.setInteractive();
        this.scene.input.setDraggable(this);
      }
      this.setShadowOffset(DOT_SHADOW_OFFSET);
    } else {
      this.name = 'dotShadow';
    }
    scene.add.existing(this);
  }

  setShadowOffset(x: number, y?: number) {
    this.shadowOffset = { x, y: y || x };
    this.setShadowPosition();
  }

  setShadowPosition() {
    if (!this.shadow) return;
    const { x, y } = this.shadowOffset;
    this.shadow.setPosition(this.x + x, this.y + y);
  }

  shadowInPosition() {
    if (!this.shadow) return true;
    const { x, y } = this.shadowOffset;
    return this.shadow.x === this.x + x && this.shadow.y === this.y + y;
  }

  preUpdate() {
    if (this.shadow && !this.shadowInPosition() && this.shadowFollow) {
      this.setShadowPosition();
    }
  }
}

const DOT_CONNECTOR_COLOR = 0x808586;
const DOT_CONNECTOR_SHADOW_COLOR = 0xB7BBBB;
const DOT_CONNECTOR_LINE_WIDTH = 3;

class DotConnector extends Phaser.GameObjects.Line {
  name = 'dotConnector';

  d1: Dot;

  d2: Dot;

  shadow: DotConnector | undefined;

  shadowOffset: IShadowPosition = { x: 0, y: 0 };

  shadowFollow: boolean = true;

  constructor(
    scene: Phaser.Scene,
    d1: Dot,
    d2: Dot,
    isShadow: boolean = false,
  ) {
    super(scene, 0, 0, d1.x, d1.y, d2.x, d2.y, !isShadow ? DOT_CONNECTOR_COLOR : DOT_CONNECTOR_SHADOW_COLOR);
    this.d1 = d1;
    this.d2 = d2;
    this.setData('d1Pos', { x: d1.x, y: d1.y });
    this.setData('d2Pos', { x: d2.x, y: d2.y });
    this.setOrigin(0);
    this.setLineWidth(DOT_CONNECTOR_LINE_WIDTH);
    if (!isShadow && this.d1.shadow && this.d2.shadow) {
      this.shadow = new DotConnector(scene, this.d1.shadow, this.d2.shadow, true);
    }
    scene.add.existing(this);
  }

  positionChanged(key: string, dot: Dot) {
    const pos = this.getData(key);
    return pos.x !== dot.x || pos.y !== dot.y;
  }

  preUpdate() {
    if (this.positionChanged('d1Pos', this.d1) || this.positionChanged('d2Pos', this.d2)) {
      this.setTo(this.d1.x, this.d1.y, this.d2.x, this.d2.y);
    }
  }
}

interface IConnectedDotsDMap {
  x: number,
  y: number,
}

interface IConnectedDotsCMap {
  from: number,
  to: number,
}

export interface IConnectedDotsMap {
  dots: IConnectedDotsDMap[];
  connections: IConnectedDotsCMap[];
}

export class ConnectedDots extends Phaser.GameObjects.Container {
  name = 'connectedDots';

  dots: Dot[];

  lines: DotConnector[];

  constructor(
    scene: Phaser.Scene,
    x: number = 0,
    y: number = 0,
    map: IConnectedDotsMap,
  ) {
    super(scene, x, y);
    this.dots = [];
    this.lines = [];

    for (let i = 0; i < map.dots.length; i++) {
      this.addDot(map.dots[i].x, map.dots[i].y);
    }
    for (let i = 0; i < map.connections.length; i++) {
      this.connectNodes(map.connections[i].from, map.connections[i].to);
    }

    const lineShadows = this.lines.reduce((res: DotConnector[], l) => {
      if (l.shadow) {
        res.push(l.shadow);
      }
      return res;
    }, []);

    const dotShadows = this.dots.reduce((res: Dot[], d) => {
      if (d.shadow) {
        res.push(d.shadow);
      }
      return res;
    }, []);
    this.add([...lineShadows, ...dotShadows, ...this.lines, ...this.dots]);
    scene.add.existing(this);
  }

  addDot(x: number, y: number) {
    const dot = new Dot(this.scene, x, y, this.dots.length);
    this.dots.push(dot);
  }

  connectNodes(from: number, to: number) {
    const line = new DotConnector(this.scene, this.dots[from], this.dots[to]);
    this.lines.push(line);
  }

  getDot(i: number): Dot {
    if (this.dots[i]) {
      return this.dots[i];
    }
      throw new Error(`requested dot at illegal index ${i}`);
  }

  compress(x: number, y: number, exclude: number[] = []): ConnectedDots {
    for (let i = 0; i < this.dots.length; i++) {
      if (!exclude.find((e) => e === i)) {
        this.dots[i].setPosition(x, y);
        this.dots[i].setData('compressedPos', { x, y });
      }
    }
    return this;
  }

  decompress(val: number, exclude: number[] = []): ConnectedDots {
    for (let i = 0; i < this.dots.length; i++) {
      if (!exclude.find((e) => e === i)) {
        const d = this.dots[i];
        const cPos = d.getData('compressedPos');
        const origPos = d.getData('origPos');
        d.x = convertRange(val, 0, 1, cPos.x, origPos.x);
        d.y = convertRange(val, 0, 1, cPos.y, origPos.y);
      }
    }
    return this;
  }

  setShadowOffset(x: number, y?: number): ConnectedDots {
    const so = { x, y: y || x };
    for (let i = 0; i < this.dots.length; i++) {
      this.dots[i].setShadowOffset(so.x, so.y);
    }
    return this;
  }

  setOrigPos(): ConnectedDots {
    for (let i = 0; i < this.dots.length; i++) {
      const origPos = this.dots[i].getData('origPos');
      this.dots[i].setPosition(origPos.x, origPos.y);
    }
    return this;
  }
}

// DEBUG FUNCTIONS
export function enableDebugDragDots(scene: Phaser.Scene) {
  scene.input.on('drag', (p: any, go: any, dX: number, dY: number) => {
    if (go.name === 'dot') {
      // eslint-disable-next-line no-param-reassign
      go.x = dX;
      // eslint-disable-next-line no-param-reassign
      go.y = dY;
    }
  });
  scene.input.on('dragend', (p: any, go: any) => {
    if (go.name === 'dot') {
      devLog(`x: ${go.x}, y:${go.y}, index - ${go.getData('dotIndex')}`);
    }
  });
}
