import React, { useState, useEffect } from 'react';
import { isMobile } from 'mobile-device-detect';

const ProgressPercent: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    PubSub.subscribe('loadingProgress', (msg: string, data: any) => {
      setProgress(data.value);
    });
  }, []);
  return <p className={`progress ${isMobile ? 'mobile' : ''}`}>{`${(progress * 100).toFixed(0)}%`}</p>;
};

export default ProgressPercent;
