export interface IGridTileChildren {
  shadow: Phaser.GameObjects.Rectangle;
  bottom: Phaser.GameObjects.Rectangle;
  top: Phaser.GameObjects.Rectangle;
}

export interface IGridTileChildrenProps {
  offset: number;
  shadowColor: number;
  bottomColor: number;
  topColor: number;
  floorColor: number;
}

const defaultChildrenProps: IGridTileChildrenProps = {
  offset: 30,
  shadowColor: 0xBBBDBD,
  bottomColor: 0x000000,
  topColor: 0xFFFFFF,
  floorColor: 0xDFE1E0,
};

export class GridTile extends Phaser.GameObjects.Container {
  name = 'gridTile';

  children: IGridTileChildren;

  childrenProps: IGridTileChildrenProps;

  index: number;

  currentDelta: number = 1;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    size: number = 20,
    index: number,
    childrenProps: Partial<IGridTileChildrenProps> = {},
  ) {
    super(scene, x, y);
    this.index = index;
    this.childrenProps = {
      ...defaultChildrenProps,
      ...childrenProps,
    };

    const { offset, shadowColor, bottomColor, topColor } = this.childrenProps;
    this.children = {
      shadow: new Phaser.GameObjects.Rectangle(scene, offset, 0, size, size, shadowColor, 1),
      bottom: new Phaser.GameObjects.Rectangle(scene, 0, 0, size, size, bottomColor, 1),
      top: new Phaser.GameObjects.Rectangle(scene, 0, -offset, size, size, topColor, 1),
    };
    const { shadow, bottom, top } = this.children;
    this.add([shadow, bottom, top]);
    this.reset();
    scene.add.existing(this);
  }

  reset() {
    this.setChildrenPosition(1);
  }

  setChildrenPosition(delta: number) {
    const d = delta < 0 ? 0 : delta;
    const position = d * this.childrenProps.offset;
    const { shadow, top } = this.children;
    shadow.x = position * 1;
    top.y = -position;
    const { floorColor, topColor } = this.childrenProps;
    const clampedVal = Phaser.Math.Clamp(delta, 0, 1);
    const color = Phaser.Display.Color.Interpolate.ColorWithColor(
      Phaser.Display.Color.ValueToColor(floorColor),
      Phaser.Display.Color.ValueToColor(topColor),
      1, clampedVal);
    top.fillColor = parseInt(Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a).replace(/^#/, ''), 16);
    this.currentDelta = delta;
    if ((!d && this.visible)) {
      this.setVisible(false);
    } else if (d && !this.visible) {
      this.setVisible(true);
    }
  }

  setChildProps(props: Partial<IGridTileChildrenProps>) {
    this.childrenProps = {
      ...this.childrenProps,
      ...props,
    };
  }
}
