import { INTERACTIVE_CURSOR } from '../../../lib/constants';
import { convertRange } from '../../../lib/utils';

export interface ISquareButtonChildren {
  shadow: Phaser.GameObjects.Rectangle;
  bottom: Phaser.GameObjects.Rectangle;
  top: Phaser.GameObjects.Rectangle;
  cover: Phaser.GameObjects.Rectangle;
}

export interface ISquareButtonChildrenProps {
  offset: number;
  shadowColor: number;
  bottomColor: number;
  bottomClickedColor: number;
  topColor: number;
  coverColor: number;
  floorColor: number;
}

export interface ISquareButtonStateProps {
  hidden: boolean;
  clicked: boolean;
}

const defaultChildrenProps: ISquareButtonChildrenProps = {
  offset: 40,
  shadowColor: 0xBBBDBD,
  bottomColor: 0x000000,
  bottomClickedColor: 0x000000,
  topColor: 0xFFFFFF,
  coverColor: 0x201D21,
  floorColor: 0xDFE1E0,
};

const DEFAULT_HIDE_TRANSITION = 250;

const DEFAULT_CLICK_TRANSITION = 300;

const CLICKED_POS = 0.5;

export class SquareButton extends Phaser.GameObjects.Container {
  name = 'squareButton';

  children: ISquareButtonChildren;

  childrenProps: ISquareButtonChildrenProps;

  buttonState: ISquareButtonStateProps;

  onClick: Function;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    size: number = 300,
    childrenProps: Partial<ISquareButtonChildrenProps> = {},
    onClick: Function = () => { },
  ) {
    super(scene, x, y);

    this.childrenProps = {
      ...defaultChildrenProps,
      ...childrenProps,
    };

    this.buttonState = {
      hidden: false,
      clicked: false,
    };

    this.onClick = onClick;

    const { offset, shadowColor, bottomColor, topColor, coverColor } = this.childrenProps;
    this.children = {
      shadow: new Phaser.GameObjects.Rectangle(scene, offset, 0, size, size, shadowColor, 1),
      bottom: new Phaser.GameObjects.Rectangle(scene, 0, 0, size, size, bottomColor, 1),
      top: new Phaser.GameObjects.Rectangle(scene, 0, -offset, size, size, topColor, 1),
      cover: new Phaser.GameObjects.Rectangle(scene, 0, -offset, size, size, coverColor, 0),
    };
    const { shadow, bottom, top, cover } = this.children;
    this.add([shadow, bottom, top, cover]);
    top.setInteractive({ cursor: INTERACTIVE_CURSOR });
    top.on('pointerup', () => { this.onClick(); });
    this.reset();
    scene.add.existing(this);
  }

  reset() {
    this.setChildrenPosition(1);
    this.children.cover.fillAlpha = 0;
    this.children.cover.setScale(0);
  }

  setChildrenPosition(delta: number) {
    const d = delta < 0 ? 0 : delta;
    const position = d * this.childrenProps.offset;
    const { shadow, top, cover } = this.children;
    shadow.x = position;
    top.y = -position;
    cover.y = -position;
  }

  hide(
    duration: number = DEFAULT_HIDE_TRANSITION,
    reveal: boolean = false,
    onComplete: Function = () => { },
  ) {
    const { top, cover } = this.children;
    const { floorColor, topColor, offset } = this.childrenProps;

    const origTopColorC = Phaser.Display.Color.ValueToColor(topColor);
    const topColorC = Phaser.Display.Color.ValueToColor(top.fillColor);
    const floorColorC = Phaser.Display.Color.ValueToColor(floorColor);

    const hide = !reveal;
    const fromPos = hide ? -top.y / offset : 0;
    const toPos = hide ? 0 : 1;
    const fromAlpha = hide ? cover.fillAlpha : 0;
    const toAlpha = hide ? 0 : cover.fillAlpha;
    const fromColor = hide ? topColorC : floorColorC;
    const toColor = hide ? floorColorC : origTopColorC;

    this.buttonState.hidden = hide;
    top.disableInteractive();
    if (!duration) {
      this.setChildrenPosition(toPos);
      cover.fillAlpha = toAlpha;
      top.setFillStyle(hide ? floorColor : topColor);
      onComplete();
    } else {
      this.scene.tweens.addCounter({
        duration,
        from: 0,
        to: 1,
        ease: 'Back',
        onUpdate: (t) => {
          const val = t.getValue();
          const pos = convertRange(val, 0, 1, fromPos, toPos);
          this.setChildrenPosition(pos);
          const clampedVal = Phaser.Math.Clamp(val, 0, 1);
          cover.fillAlpha = convertRange(clampedVal, 0, 1, fromAlpha, toAlpha);
          const color = Phaser.Display.Color.Interpolate.ColorWithColor(fromColor, toColor, 1, clampedVal);
          top.fillColor = parseInt(Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a).replace(/^#/, ''), 16);
        },
        onComplete: () => {
          onComplete();
        },
      });
    }
  }

  reveal(duration?: number | undefined, onComplete = () => { }) {
    this.hide(duration, true, onComplete);
  }

  click(
    duration: number = DEFAULT_CLICK_TRANSITION,
    unclick: boolean = false,
    onComplete: Function = () => { },
  ) {
    const { top, bottom, cover } = this.children;
    const { bottomColor, bottomClickedColor, offset } = this.childrenProps;
    const origBottomColorC = Phaser.Display.Color.ValueToColor(bottomColor);
    const bottomColorC = Phaser.Display.Color.ValueToColor(bottom.fillColor);
    const bottomClickedColorC = Phaser.Display.Color.ValueToColor(bottomClickedColor);

    const click = !unclick;
    const fromPos = -top.y / offset;
    const toPos = click ? CLICKED_POS : 1;

    const fromAlpha = cover.fillAlpha;
    const toAlpha = click ? 1 : 0;
    const fromScale = cover.scale;
    const toScale = click ? 1 : 0;

    const fromColor = click ? bottomColorC : bottomClickedColorC;
    const toColor = click ? bottomClickedColorC : origBottomColorC;

    this.buttonState.clicked = click;
    top.disableInteractive();
    if (!duration) {
      this.setChildrenPosition(toPos);
      cover.fillAlpha = toAlpha;
      onComplete();
    } else {
      this.scene.tweens.addCounter({
        duration,
        from: 0,
        to: 1,
        ease: 'Back',
        onUpdate: (t) => {
          const val = t.getValue();
          const pos = convertRange(val, 0, 1, fromPos, toPos);
          this.setChildrenPosition(pos);
          const clampedVal = Phaser.Math.Clamp(val, 0, 1);
          cover.fillAlpha = convertRange(clampedVal, 0, 1, fromAlpha, toAlpha);
          cover.scale = convertRange(clampedVal, 0, 1, fromScale, toScale);
          const color = Phaser.Display.Color.Interpolate.ColorWithColor(fromColor, toColor, 1, clampedVal);
          bottom.fillColor = parseInt(Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a).replace(/^#/, ''), 16);
        },
        onComplete: () => {
          onComplete();
        },
      });
    }
  }

  unclick(duration?: number | undefined, onComplete = () => { }) {
    this.click(duration, true, onComplete);
  }

  setChildProps(props: Partial<ISquareButtonChildrenProps>) {
    this.childrenProps = {
      ...this.childrenProps,
      ...props,
    };
  }
}
