export const phaser = {
  titleState: {
    clickDesktop: 'Click It',
    clickMobile: 'Touch It',
  },
  soundOn: 'Sound:On',
  soundOff: 'Sound:Off',
  resume: 'Rez.PDF',
  instructions: 'Small Buttons Select<br>Big Button Provides',
};
