import { isMobile } from 'mobile-device-detect';
import { MainStateContainer } from './main-state';
import { CameraFocus } from '../../../classes/camera/camera-focus';
import { Grid } from '../../../classes/main-state/grid';
import { MainInstructions } from '../../../classes/main-state/main-instruction';
import { CategoryController } from '../../../classes/main-state/category-controller';

export function createMainStateContainer(scene: Phaser.Scene, cf: CameraFocus) {
  const sc = new MainStateContainer(scene, 0, 0, [], cf);
  // create children
  const cols = 50;
  const rows = 7;
  const size = isMobile ? 20 : 25;
  const startX = (-cols + 1) / 2 * size;
  const startY = (-rows + 1) / 2 * size;
  const grid = new Grid(scene, startX, startY, cols, rows, size);
  grid.setTilesWithKey('select');

  const categoryController = new CategoryController(scene, grid);

  sc.addChildren([
    { id: 'instructions', go: new MainInstructions(scene) },
    { id: 'tiles', go: grid.getTiles() },
    ...categoryController.getChildren(),
  ]);

  // FOR DEBUG ONLY
  // scene.sound.play('musicLoop', { loop: true, volume: 0.25 });
  // const index = 0;
  // const gridMapKeys: GridMapIndex[] = ['about', 'hello', 'skills', 'work'];

  // setInterval(() => {
  //   grid.tweenTilesWithKey(gridMapKeys[index]);
  //   index = (index + 1) % gridMapKeys.length;
  //   if (index === 0) {
  //     const key = gridMapKeys.pop();
  //     Phaser.Utils.Array.Shuffle(gridMapKeys);
  //     if (key) {
  //       gridMapKeys.push(key);
  //     }
  //   }
  // }, 5000);
  return sc;
}
