import { isAndroid, isSafari } from 'mobile-device-detect';

const lennyFaces = {
  default: '\n  ͡°  ͜ʖ  ͡° \n',
  blink: '\n  ͡~  ͜ʖ  ͡~ \n',
};

function chooseFontFamily() {
  if (isSafari) {
    return 'sans-serif';
  } if (isAndroid) {
    return 'Georgia';
  }
  return 'arial';
}

const textStyle = {
  color: '#000000',
  fontSize: '32px',
  fontFamily: chooseFontFamily(),
  resolution: 2,
};

interface IDiscFaceBlinkTimeRange {
  min: number;
  max: number;
}

export class DiscFace extends Phaser.GameObjects.Text {
  name = 'discFace';

  blinkTimeRange: IDiscFaceBlinkTimeRange = {
    min: 2000,
    max: 4000,
  };

  shouldBlink: boolean = false;

  nextBlinkTime: number;

  blinkSound:
    Phaser.Sound.WebAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.BaseSound | undefined = undefined;

  constructor(
    scene: Phaser.Scene,
    x: number = 0,
    y: number = 0,
    blinkSound?: Phaser.Sound.WebAudioSound | Phaser.Sound.HTML5AudioSound | undefined,
  ) {
    super(scene, isSafari ? x : x - 5, y, lennyFaces.default, textStyle);
    this.setOrigin(0.5);
    this.nextBlinkTime = this.getRandomBlinkTime();
    if (blinkSound) {
      this.blinkSound = blinkSound;
    }
    scene.add.existing(this);
  }

  setBlink(shouldBlink: boolean, blinkTime?: number) {
    this.shouldBlink = shouldBlink;
    if (this.shouldBlink) {
      this.nextBlinkTime = this.scene.game.getTime() + (blinkTime || this.getRandomBlinkTime());
    } else {
      this.setText(lennyFaces.default);
    }
  }

  getRandomBlinkTime() {
    return Phaser.Math.Between(this.blinkTimeRange.min, this.blinkTimeRange.max);
  }

  preUpdate(time: number) {
    if (time > this.nextBlinkTime && this.shouldBlink) {
      const isBlinking = this.text === lennyFaces.blink;
      this.setText(isBlinking ? lennyFaces.default : lennyFaces.blink);
      if (!isBlinking && this.blinkSound) {
        this.blinkSound?.play();
      }
      this.nextBlinkTime = time + (isBlinking ? this.getRandomBlinkTime() : 100);
    }
  }
}
