import { devLog } from '../../../lib/utils';
import { CameraFocus } from '../camera/camera-focus';

interface IStateContainerChild {
  id: string;
  go: any;
}

export class StateContainer extends Phaser.GameObjects.Container {
  name = 'stateContainer';

  target: CameraFocus;

  holdX: boolean;

  holdY: boolean;

  children: IStateContainerChild[] = [];

  sounds: any[] = [];

  constructor(
    scene: Phaser.Scene,
    x: number = 0,
    y: number = 0,
    children: any[] = [],
    target: CameraFocus,
    holdX: boolean = true,
    holdY: boolean = true,
  ) {
    super(scene, x, y, children.map((c) => c.go));
    this.children = children;
    this.target = target;
    this.holdX = holdX;
    this.holdY = holdY;
    scene.add.existing(this);
  }

  setHold(x?: boolean, y?: boolean) {
    if (x) {
      this.holdX = x;
    }
    if (y) {
      this.holdY = y;
    }
    return this;
  }

  addChildren(children: IStateContainerChild[]) {
    this.children.push(...children);
    for (let i = 0; i < children.length; i += 1) {
      const { go } = children[i];
      super.add(go);
    }
    return this;
  }

  getChildById(id: string) {
    const child = this.children.find((c) => c.id === id);
    if (child) {
      return child.go;
    }
    throw new Error(`No child with id ${id} in container ${this.name}`);
  }

  checkHoldPosition() {
    if (this.holdX && (this.target.x !== this.x)) {
      this.x = this.target.x;
    }
    if (this.holdY && (this.target.y !== this.y)) {
      this.y = this.target.y;
    }
  }

  reset() {
    devLog(`${this.name} reset`);
  }

  async onEnter(): Promise<any> {
    devLog(`${this.name} enter`);
  }

  async onEnterComplete(): Promise<any> {
    devLog(`${this.name} start`);
  }

  async onExit(): Promise<any> {
    devLog(`${this.name} exit`);
  }

  preUpdate() {
    this.checkHoldPosition();
  }
}
