import { DEFAULT_AUDIO_LEVEL } from '../../lib/constants';
import { isDev, setCameraZoom } from '../../lib/utils';
import { CameraFocus } from '../classes/camera/camera-focus';
import { CameraRangeFinder } from '../classes/camera/camera-range-finder';
import { DEBUG_DRAG_DOTS, enableDebugDragDots } from '../classes/title-state/connected-dots';
import { ResumeButton } from '../classes/button/resume-button';
import { SoundControl } from '../classes/button/sound-control';
import { StateManager } from '../classes/state/state-manager';
import { createMainStateContainer } from './states/main/main-state-create';
import { createTitleStateContainer } from './states/title/title-state-create';

export default async function create(this: Phaser.Scene) {
  // add sounds to scene
  this.sound.add('part1', { volume: DEFAULT_AUDIO_LEVEL });
  this.sound.add('part2', { volume: DEFAULT_AUDIO_LEVEL });
  this.sound.add('part3', { volume: DEFAULT_AUDIO_LEVEL });
  this.sound.add('part4', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('part5', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('blink', { volume: DEFAULT_AUDIO_LEVEL / 10 });
  this.sound.add('shuffle', { volume: DEFAULT_AUDIO_LEVEL / 10 });
  this.sound.add('panelUp', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('panelDown', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('musicLoop', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('bigButtonPress', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('helloCat', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('aboutCat', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('workCat', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('skillsCat', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('helloSq', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('aboutSq', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('workSq', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('skillsSq', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('helloUp', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('helloDown', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('aboutUp', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('aboutDown', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('workUp', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('workDown', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('skillsUp', { volume: DEFAULT_AUDIO_LEVEL / 3 });
  this.sound.add('skillsDown', { volume: DEFAULT_AUDIO_LEVEL / 3 });

  // set event listeners
  setPubSub(this);

  const cf = new CameraFocus(this, 0, 0);
  new CameraRangeFinder(this, cf);

  const stateManager = new StateManager(this);
  this.game.registry.set('stateManager', stateManager);

  // Create Title State
  const titleStateContainer = createTitleStateContainer(this, cf);
  const mainStateContainer = createMainStateContainer(this, cf);
  stateManager.addStates([
    { id: 'title', state: titleStateContainer },
    { id: 'main', state: mainStateContainer },
  ]);
  stateManager.setInitialActive('title', true);

  const soundControl = new SoundControl(this);
  const resumeButton = new ResumeButton(this);
  // create options container
  this.add.container(0, 0, [soundControl, resumeButton]);

  setCameraZoom(this.cameras.main, true);

  if (isDev && DEBUG_DRAG_DOTS) {
    enableDebugDragDots(this);
  }
}

function setPubSub(scene: Phaser.Scene) {
  PubSub.subscribe('playSound', (msg: string, data: any) => {
    const { key } = data;
    if (key) {
      scene.sound.get(key)!.play();
    }
  });
}
