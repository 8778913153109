import { INTERACTIVE_CURSOR } from '../../../lib/constants';
import { GridMapIndex } from '../../../lib/grid-maps';
import { convertRange } from '../../../lib/utils';
import { GridKeyColorMap } from '../main-state/grid';

export interface ICategorySelectButtonChildren {
  shadow: Phaser.GameObjects.Rectangle;
  bottom: Phaser.GameObjects.Rectangle;
  top: Phaser.GameObjects.Rectangle;
  topInner: Phaser.GameObjects.Rectangle;
  cover: Phaser.GameObjects.Rectangle;
}

export interface ICategorySelectButtonChildrenProps {
  offset: number;
  shadowColor: number;
  bottomColor: number;
  bottomClickedColor: number;
  topColor: number;
  topInnerColor: number;
  coverColor: number;
}

export interface ICategorySelectButtonStateProps {
  hidden: boolean;
  clicked: boolean;
}

const defaultChildrenProps: ICategorySelectButtonChildrenProps = {
  offset: 40,
  shadowColor: 0xBBBDBD,
  bottomColor: 0x000000,
  bottomClickedColor: 0x000000,
  topColor: 0xFFFFFF,
  topInnerColor: 0x000000,
  coverColor: 0x201D21,
};

const DEFAULT_CLICK_TRANSITION = 300;

const CLICKED_POS = 0.5;

export class CategorySelectButton extends Phaser.GameObjects.Container {
  name = 'categorySelectButton';

  children: ICategorySelectButtonChildren;

  childrenProps: ICategorySelectButtonChildrenProps;

  buttonState: ICategorySelectButtonStateProps;

  onClick: Function;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    size: number = 150,
    gridMapIndex: GridMapIndex,
    onClick: Function,
  ) {
    super(scene, x, y);

    const mapColors = GridKeyColorMap[gridMapIndex];

    this.childrenProps = {
      ...defaultChildrenProps,
      topInnerColor: mapColors.bottomColor,
      bottomColor: mapColors.topColor,
      bottomClickedColor: mapColors.bottomColor,
      coverColor: mapColors.coverColor,
    };

    this.buttonState = {
      hidden: false,
      clicked: false,
    };

    this.onClick = onClick;

    const { offset, shadowColor, bottomColor, topColor, topInnerColor, coverColor } = this.childrenProps;
    this.children = {
      shadow: new Phaser.GameObjects.Rectangle(scene, offset, 0, size, size, shadowColor, 1),
      bottom: new Phaser.GameObjects.Rectangle(scene, 0, 0, size, size, bottomColor, 1),
      top: new Phaser.GameObjects.Rectangle(scene, 0, -offset, size, size, topColor, 1),
      topInner: new Phaser.GameObjects.Rectangle(scene, 0, -offset, size / 2, size / 2, topInnerColor, 1),
      cover: new Phaser.GameObjects.Rectangle(scene, 0, -offset, size, size, coverColor, 0),
    };
    const { shadow, bottom, top, topInner, cover } = this.children;
    this.add([shadow, bottom, top, topInner, cover]);
    top.setInteractive({ cursor: INTERACTIVE_CURSOR });
    top.on('pointerup', () => { this.click(); });
    this.reset();
    scene.add.existing(this);
  }

  reset() {
    this.setChildrenPosition(1);
    this.children.cover.fillAlpha = 0;
    this.children.cover.setScale(0);
  }

  setChildrenPosition(delta: number) {
    const d = delta < 0 ? 0 : delta;
    const position = d * this.childrenProps.offset;
    const { shadow, top, topInner, cover } = this.children;
    shadow.x = position;
    top.y = -position;
    topInner.y = -position;
    cover.y = -position;
  }

  click(
    duration: number = DEFAULT_CLICK_TRANSITION,
    unclick: boolean = false,
    onClick?: Function,
    onComplete: Function = () => { },
  ) {
    const { top, bottom, cover } = this.children;
    const { bottomColor, bottomClickedColor, offset } = this.childrenProps;
    const origBottomColorC = Phaser.Display.Color.ValueToColor(bottomColor);
    const bottomColorC = Phaser.Display.Color.ValueToColor(bottom.fillColor);
    const bottomClickedColorC = Phaser.Display.Color.ValueToColor(bottomClickedColor);

    const click = !unclick;
    const fromPos = -top.y / offset;
    const toPos = click ? CLICKED_POS : 1;

    const fromAlpha = cover.fillAlpha;
    const toAlpha = click ? 1 : 0;
    const fromScale = cover.scale;
    const toScale = click ? 1 : 0;

    const fromColor = click ? bottomClickedColorC : bottomColorC;
    const toColor = click ? bottomClickedColorC : origBottomColorC;

    this.buttonState.clicked = click;
    top.disableInteractive();
    if (onClick) {
      onClick();
    } else {
      this.onClick();
    }
    if (!duration) {
      this.setChildrenPosition(toPos);
      cover.fillAlpha = toAlpha;
      onComplete();
    } else {
      this.scene.tweens.addCounter({
        duration,
        from: 0,
        to: 1,
        ease: 'Back',
        onUpdate: (t) => {
          const val = t.getValue();
          const pos = convertRange(val, 0, 1, fromPos, toPos);
          this.setChildrenPosition(pos);
          const clampedVal = Phaser.Math.Clamp(val, 0, 1);
          cover.fillAlpha = convertRange(clampedVal, 0, 1, fromAlpha, toAlpha);
          cover.scale = convertRange(clampedVal, 0, 1, fromScale, toScale);
          const color = Phaser.Display.Color.Interpolate.ColorWithColor(fromColor, toColor, 1, clampedVal);
          bottom.fillColor = parseInt(Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a).replace(/^#/, ''), 16);
        },
        onComplete: () => {
          onComplete();
        },
      });
    }
  }

  unclick(duration?: number | undefined) {
    this.click(duration, true);
  }
}
