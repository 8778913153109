import PubSub from 'pubsub-js';
import { TFunction } from 'react-i18next';
import { isMobile } from 'mobile-device-detect';
import { CameraFocus } from '../../../classes/camera/camera-focus';
import { ConnectedDots, DOT_SHADOW_OFFSET } from '../../../classes/title-state/connected-dots';
import { CrossButton } from '../../../classes/button/cross-button';
import { SquareButton } from '../../../classes/button/square-button';
import { TitleDisc } from '../../../classes/title-state/title-disc';
import { TitleDotMap } from '../../../../lib/connected-dot-maps';
import { TitleStateContainer } from './title-state';
import { PHASER_DOM_DEFAULT_TEXT_STYLE } from '../../../../lib/constants';
import { ANIM_DURATION } from '../../../../components/TextOverlay/TextOverlay';
import { titleIntroAnimation } from './title-state-funcs';
import { StateManager } from '../../../classes/state/state-manager';
import { sleep } from '../../../../lib/utils';

export function createTitleStateContainer(scene: Phaser.Scene, cf: CameraFocus) {
  const t = scene.game.registry.get('t') as TFunction;
  const stateManager = scene.game.registry.get('stateManager') as StateManager;
  const { sound } = scene;
  const sc = new TitleStateContainer(scene, 0, 0, [], cf);

  // create children
  const dots = new ConnectedDots(scene, 0, 0, TitleDotMap);
  for (let i = 0; i < dots.dots.length; i++) {
    const d = dots.dots[i];
    const op = d.getData('origPos');
    d.setPosition(op.x, op.y - DOT_SHADOW_OFFSET);
  }
  const titleDisc = new TitleDisc(scene, 0, -500);
  titleDisc.children.face.blinkSound = sound.get('blink') as Phaser.Sound.BaseSound;
  titleDisc.onClick = () => {
    titleDisc.onOut();
    titleDisc.disableInteractive();
    titleIntroAnimation(sc);
  };
  titleDisc.disableInteractive();
  const cross = new CrossButton(scene, -275, 210, {},
    () => {
      cross.hide();
      sound.get('panelUp')!.play();
      PubSub.publish('showTextOverlay', {
        key: 'intro',
        callback: () => {
          sound.get('panelDown')!.play();
          setTimeout(() => {
            cross.show();
          }, 125);
        },
        delay: (-1000 * ANIM_DURATION),
      });
    });
  const button = new SquareButton(scene, 0, 300, undefined, undefined,
    async function (this: SquareButton) {
      cross.hide();
      sound.get('musicLoop')!.play({ loop: true, volume: 0.25 });
      sound.get('bigButtonPress')!.play();
      titleDisc.children.face.setBlink(false);
      this.click(undefined, undefined,
        async () => {
          await sleep(100);
          stateManager.transitionTo('main');
        });
    });

  const clickText = scene.add.dom(
    0, 450, 'div', PHASER_DOM_DEFAULT_TEXT_STYLE,
    t(`titleState.click${isMobile ? 'Mobile' : 'Desktop'}`),
  ).setVisible(false);

  sc.addChildren([
    { id: 'dots', go: dots },
    { id: 'nextButton', go: button },
    { id: 'crossButton', go: cross },
    { id: 'titleDisc', go: titleDisc },
    { id: 'clickText', go: clickText },
  ]);
  return sc;
}
