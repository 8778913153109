import { CameraFocus } from '../../../classes/camera/camera-focus';
import { ConnectedDots } from '../../../classes/title-state/connected-dots';
import { CrossButton } from '../../../classes/button/cross-button';
import { SquareButton } from '../../../classes/button/square-button';
import { StateContainer } from '../../../classes/state/state-container';
import { TitleDisc } from '../../../classes/title-state/title-disc';

export class TitleStateContainer extends StateContainer {
  name = 'titleStateContainer';

  tweens: Phaser.Tweens.Tween[] = [];

  constructor(
    scene: Phaser.Scene,
    x: number = 0,
    y: number = 0,
    children: any[] = [],
    target: CameraFocus,
    holdX: boolean = true,
    holdY: boolean = false,
  ) {
    super(scene, x, y, children, target, holdX, holdY);
  }

  reset() {
    // get things in position
    const nextButton: SquareButton = this.getChildById('nextButton');
    const crossButton: CrossButton = this.getChildById('crossButton');
    const dots: ConnectedDots = this.getChildById('dots');
    const titleDisc: TitleDisc = this.getChildById('titleDisc');
    const clickText: Phaser.GameObjects.Text = this.getChildById('clickText');

    nextButton.setVisible(false).disableInteractive().hide(-1);
    crossButton.setScale(0).setVisible(false).disableInteractive();
    dots.compress(nextButton.x, nextButton.y).setShadowOffset(0);
    titleDisc.setPosition(nextButton.x, nextButton.y).setChildrenPosition(0).setInteractive();
    clickText.setAlpha(1).setVisible(false);
  }

  async onEnter() {
    this.disableInteractiveGameObjects();
  }

  async onExit() {
    this.disableInteractiveGameObjects();
  }

  disableInteractiveGameObjects() {
    const nextButton: SquareButton = this.getChildById('nextButton');
    const crossButton: CrossButton = this.getChildById('crossButton');
    const titleDisc: TitleDisc = this.getChildById('titleDisc');
    nextButton.children.top.disableInteractive();
    crossButton.disableInteractive();
    titleDisc.disableInteractive();
  }
}
